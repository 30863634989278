import React, { Suspense, lazy } from 'react';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';
import {
  HistoryListener,
  NavigationConfirmModal,
} from 'react-router-navigation-confirm';

import { useCountry } from '../contexts/CountryContext';
import PageLoading from '../components/PageLoading';
import useAuthRecoil from '../hooks/useAuthRecoil';
import ProtectedRoute from './ProtectedRoute';

import Layout from '../components/Layout';
import Supports from '../pages/Supports';
import Companies from '../pages/Companies';
import Dass from '../pages/Dass';

const Home = lazy(() => import('../pages/Home'));
const Projects = lazy(() => import('../pages/Projects'));
const ProjectDetails = lazy(() => import('../pages/ProjectDetails'));
const InvestmentContainer = lazy(() => import('../pages/InvestmentContainer'));
const MercadoPagoContainer = lazy(() =>
  import('../pages/MercadoPagoContainer'),
);
const MyInvestments = lazy(() => import('../pages/MyInvestments'));
const ReferralsProgram = lazy(() => import('../pages/ReferralsProgram'));
const HowItWorks = lazy(() => import('../pages/HowItWorks'));
const About = lazy(() => import('../pages/About'));
const Error404 = lazy(() => import('../pages/Error404'));
const ConstructionPage = lazy(() => import('../pages/Constrution'));
const Profile = lazy(() => import('../pages/Profile'));
const TermsConditions = lazy(() => import('../pages/TermsConditions'));
const PrivacyPolicy = lazy(() => import('../pages/PrivacyPolicy'));
const FAQIndex = lazy(() => import('../pages/FAQIndex'));
const FAQCategory = lazy(() => import('../pages/FAQCategory'));
const FAQDetail = lazy(() => import('../pages/FAQDetail'));
const ExposedPersonPolicy = lazy(() => import('../pages/ExposedPersonPolicy'));
const MercadoLibre = lazy(() => import('../pages/MercadoLibre'));
const Blog = lazy(() => import('../pages/Blog'));
const Article = lazy(() => import('../pages/Article'));
const Products = lazy(() => import('../pages/Products'));
const ProductTypes = lazy(() => import('../pages/ProductTypes'));
const Tuluka = lazy(() => import('../pages/Tuluka'));
const Goals = lazy(() => import('../pages/Goals'));
const Investments = lazy(() => import('../pages/Investments'));
const CreateInvestment = lazy(() => import('../pages/CreateInvestment'));
const CreateGoalPage = lazy(() => import('../pages/CreateGoalPage'));
const PageInvestmentDetails = lazy(() =>
  import('../pages/PageInvestmentDetails'),
);
const PageGoalDetails = lazy(() => import('../pages/PageGoalDetails'));
const PageGoalDetailsActivity = lazy(() =>
  import('../pages/PageGoalDetailsActivity'),
);
const PageGoalInvestment = lazy(() =>
  import('../pages/PageCreateGoalInvestment'),
);
const PageManualReinvestment = lazy(() =>
  import('../pages/PageCreateManualReinvestment'),
);
const Boost = lazy(() => import('../pages/Boost'));

const JobsLanding = lazy(() => import('../pages/JobsLanding'));

const AppRouter = () => {
  return (
    <Layout>
      <>
        <HistoryListener>
          <NavigationConfirmModal
            confirmText={'Continuar'}
            cancelText={'Cancelar'}
            buttonConfirmClassName={'cancel-button'}
            when={(...args) => {
              if (args[0].state?.update === true) {
                return true;
              }
              return false;
            }}
          >
            <div>
              <h2>¿Esta seguro que desea salir?</h2>
              <br />
              <p>Los datos sin guardar se perderan</p>
            </div>
          </NavigationConfirmModal>
        </HistoryListener>
      </>
      <Suspense fallback={<PageLoading />}>
        <Switch>
          <MyRoutes />
        </Switch>
      </Suspense>
    </Layout>
  );
};

const MyRoutes = () => {
  const { countryPrefix } = useCountry();
  const location = useLocation();

  const {
    auth: { isAuth },
  } = useAuthRecoil();

  if (!countryPrefix) {
    return <PageLoading />;
  } else {
    if (location.pathname === '/' || location.pathname === `${countryPrefix}`) {
      return <Redirect to={`${countryPrefix}/`} />;
    }
  }

  return (
    <Suspense fallback={<PageLoading />}>
      <Switch>
        <Route exact path={`${countryPrefix}`}>
          <Home />
        </Route>

        <Redirect
          from="/proyecto_inmobiliario"
          to={`${countryPrefix}/proyectos-inmobiliarios/`}
        />

        <Redirect
          exact
          from="/proyectos-inmobiliarios/proyecto/"
          to={`${countryPrefix}/proyectos-inmobiliarios/`}
        />
        <Route path={`${countryPrefix}/proyectos-inmobiliarios/:id/inversion`}>
          <InvestmentContainer />
        </Route>
        <Route
          path={`${countryPrefix}/proyectos-inmobiliarios/:id/mercadopago/:status`}
        >
          <MercadoPagoContainer />
        </Route>

        {isAuth && (
          <Route exact path={`${countryPrefix}/mi-perfil`}>
            <Profile />
          </Route>
        )}

        <Route exact path={`${countryPrefix}/productos`}>
          <Products />
        </Route>

        <Route exact path={`${countryPrefix}/productos/:productType`}>
          <ProductTypes />
        </Route>

        <Route
          exact
          path={`${countryPrefix}/proyectos-inmobiliarios/:filters?`}
        >
          <Projects />
        </Route>

        <Route
          // exact
          path={`${countryPrefix}/proyectos-inmobiliarios/proyecto/:slug`}
        >
          <ProjectDetails />
        </Route>

        <ProtectedRoute
          exact
          path={`${countryPrefix}/dashboard/inversiones/detalle-inversion/:id`}
          isAuth={isAuth}
        >
          <PageInvestmentDetails />
        </ProtectedRoute>

        <ProtectedRoute
          path={`${countryPrefix}/dashboard/inversiones/nueva-inversion`}
          isAuth={isAuth}
        >
          <CreateInvestment />
        </ProtectedRoute>

        <ProtectedRoute
          exact={true}
          path={`${countryPrefix}/dashboard/inversiones/nueva-reinversion`}
          isAuth={isAuth}
        >
          <PageManualReinvestment />
        </ProtectedRoute>

        <ProtectedRoute
          path={`${countryPrefix}/dashboard/inversiones`}
          isAuth={isAuth}
        >
          <Investments />
        </ProtectedRoute>

        <ProtectedRoute
          exact={true}
          path={`${countryPrefix}/dashboard/metas/nueva-inversion-meta/:id/:method?`}
          isAuth={isAuth}
        >
          <PageGoalInvestment />
        </ProtectedRoute>

        <ProtectedRoute
          exact
          path={`${countryPrefix}/dashboard/metas/detalle-meta/:id/inversion`}
          isAuth={isAuth}
        >
          <PageGoalDetailsActivity />
        </ProtectedRoute>

        <ProtectedRoute
          exact
          path={`${countryPrefix}/dashboard/metas/detalle-meta/:id`}
          isAuth={isAuth}
        >
          <PageGoalDetails />
        </ProtectedRoute>

        <ProtectedRoute
          path={`${countryPrefix}/dashboard/metas/nueva-meta`}
          isAuth={isAuth}
        >
          <CreateGoalPage />
        </ProtectedRoute>

        <Redirect
          from={`${countryPrefix}/metas`}
          to={{
            pathname: `${countryPrefix}/productos`,
            hash: '#metas',
            search: location.search && location.search,
          }}
        />

        <ProtectedRoute
          path={`${countryPrefix}/dashboard/metas`}
          isAuth={isAuth}
        >
          <Goals />
        </ProtectedRoute>

        <ProtectedRoute
          path={`${countryPrefix}/dashboard/potenciar`}
          isAuth={isAuth}
        >
          <Boost />
        </ProtectedRoute>

        <ProtectedRoute path={`${countryPrefix}/dashboard`} isAuth={isAuth}>
          <MyInvestments />
        </ProtectedRoute>

        <Route exact path={`${countryPrefix}/mi-perfil/referidos`}>
          <ReferralsProgram />
        </Route>

        {/*<ProtectedRoute
            path={`${countryPrefix}/mi-perfil/inversiones/:slug?`}
            isAuth={isAuth}
          >
            <Profile />
          </ProtectedRoute>*/}

        <Route path={`${countryPrefix}/terminos-y-condiciones`}>
          <TermsConditions />
        </Route>

        <Route path={`${countryPrefix}/politica-privacidad`}>
          <PrivacyPolicy />
        </Route>

        <Route
          path={`${countryPrefix}/preguntas-frecuentes/:categorySlug/:questionSlug`}
        >
          <FAQDetail />
        </Route>

        <Route path={`${countryPrefix}/preguntas-frecuentes/:categorySlug`}>
          <FAQCategory />
        </Route>

        <Route exact path={`${countryPrefix}/preguntas-frecuentes`}>
          <FAQIndex />
        </Route>

        <Route
          path={`${countryPrefix}/politica-persona-expuesta-politicamente`}
        >
          <ExposedPersonPolicy />
        </Route>

        <Route path={`${countryPrefix}/mercadolibre`}>
          <MercadoLibre />
        </Route>

        <Route path={`${countryPrefix}/tuluka`}>
          <Tuluka />
        </Route>

        <Route path={`${countryPrefix}/nosotros`}>
          <About />
        </Route>

        <Route path={`${countryPrefix}/como-funciona`}>
          <HowItWorks />
        </Route>

        <Route path={`${countryPrefix}/proyectos-inmobiliarios`}>
          <Projects />
        </Route>

        {/* <Route exact path={`/ar/metas`}>
            <Goals />
          </Route> */}

        <Route exact path={`${countryPrefix}/simple-academy`}>
          <Blog />
        </Route>
        <Route exact path={`${countryPrefix}/simple-academy/:slug/:id`}>
          <Article />
        </Route>

        <Route exact path={`${countryPrefix}/construccion`}>
          <ConstructionPage />
        </Route>

        <Route exact path={`${countryPrefix}/respaldo`}>
          <Supports />
        </Route>

        <Route exact path={`${countryPrefix}/empresas`}>
          <Companies />
        </Route>

        <Route exact path={`${countryPrefix}/dass`}>
          <Dass />
        </Route>

        <Route exact path={`${countryPrefix}/empleos`}>
          <JobsLanding />
        </Route>

        <Route>
          <Error404 />
        </Route>
      </Switch>
    </Suspense>
  );
};

export default AppRouter;
