import React from 'react';
import { Box, Copy, VStack, HStack } from '../../../SimpleUI';
import useDesignUtils from '../../../../hooks/useDesignUtils';
import SectionLayout from '../../../Home/SectionLayout';
import { useCountry } from '../../../../contexts/CountryContext';

import SupportMobileArImg from '../../../../assets/images/support-mobile-ar.svg';
import SupportMobileMxImg from '../../../../assets/images/support-mobile-mx.svg';
import SupportFlagArgImg from '../../../../assets/images/support-flag-arg.svg';
import SupportFlagStabilityImg from '../../../../assets/images/support-flag-stability.svg';
import SupportFlagUruImg from '../../../../assets/images/support-flag-uru.svg';
import SupportFlagUsaImg from '../../../../assets/images/support-flag-usa.svg';

const WhySimplestate = () => {
  const { isMobile } = useDesignUtils();
  const { countryPrefix } = useCountry();

  const StackDirection = isMobile ? VStack : HStack;
  const isMx = countryPrefix === '/mx';
  const SupportMobileImg = isMx ? SupportMobileMxImg : SupportMobileArImg;

  return (
    <SectionLayout backgroundColor="primary">
      <Box
        width="100%"
        paddingY={{ mobile: '0px', desktop: '0px' }}
        marginY={{ mobile: '0px', desktop: '0px' }}
        paddingX={{ mobile: '0px', desktop: '0px' }}
        marginX={{ mobile: '0px', desktop: '0px' }}
      >
        <StackDirection
          alignItems="center"
          space="0px"
          paddingX={{ mobile: 'xsmall', desktop: 'medium' }}
          marginX={{ mobile: 'xsmall', desktop: 'medium' }}
          paddingY={{ mobile: '0px', desktop: '0px' }}
          marginY={{ mobile: '0px', desktop: '0px' }}
        >
          {!isMobile && (
            <Box
              tag="img"
              src={SupportMobileImg}
              width={isMx ? '480px' : '470px'}
              height={isMx ? '680px' : '633px'}
              marginRight="medium"
              paddingLeft="xlarge"
              marginLeft="xxxxxlarge"
              transform={isMx ? 'translateY(85px)' : 'translateY(71px)'}
            />
          )}

          <VStack
            width={{ mobile: '90%', desktop: '50%' }}
            space={isMobile ? 'small' : 'xsmall'}
            marginX={isMobile ? 'small' : 'xlarge'}
            paddingX={isMobile ? 'small' : 'xlarge'}
            paddingY={isMobile ? '0px' : 'xxxxlarge'}
            marginBottom={isMobile ? '0px' : 'xxxxxlarge'}
          >
            <Box
              tag="img"
              src={SupportFlagStabilityImg}
              position="absolute"
              left={{ mobile: '0', desktop: '20px' }}
              top={{ mobile: '-25px', desktop: '15px' }}
              width={{ mobile: '47px', desktop: '80px' }}
              height={{ mobile: '47px', desktop: '80px' }}
            />
            <Box
              alignItems="center"
              justifyContent="space-evenly"
              boxShadow="medium"
              borderRadius="xxlarge"
              verticalAlign="center"
              borderStyle="solid"
              marginBottom="medium"
              paddingX="medium"
            >
              <Copy
                fontWeight="bold"
                fontSize="supportWhyTitle"
                textAlign="center"
              >
                ¿POR QUE SIMPLESTATE?
              </Copy>
            </Box>

            <Box padding="small" marginBottom="xlarge">
              <Copy fontSize="companiesLogoSectionText" textAlign="center">
                Somos la empresa líder en micro inversiones en propiedades de
                LATAM y te contamos cómo lo hacemos
              </Copy>
            </Box>

            {!isMx && (
              <HStack
                alignItems="center"
                justifyContent="space-evenly"
                space="small"
              >
                <Box
                  paddingY="xsmall"
                  textAlign="center"
                  verticalAlign="middle"
                  borderRadius="xxlarge"
                  backgroundColor="white"
                  width={{ mobile: '107px', desktop: '205px' }}
                  height={{ mobile: '32px', desktop: '56px' }}
                >
                  <Copy
                    fontSize="supportPercentPill"
                    fontWeight="semibold"
                    color="_lightPurple"
                  >
                    75% USA
                    <Box
                      tag="img"
                      src={SupportFlagUsaImg}
                      transform="translateY(20%)"
                      width={{ mobile: '18px', desktop: '35px' }}
                      height={{ mobile: '18px', desktop: '35px' }}
                    />
                  </Copy>
                </Box>
                <Box
                  paddingY="xsmall"
                  textAlign="center"
                  borderRadius="xxlarge"
                  backgroundColor="white"
                  width={{ mobile: '107px', desktop: '205px' }}
                  height={{ mobile: '32px', desktop: '56px' }}
                >
                  <Copy
                    fontSize="supportPercentPill"
                    fontWeight="semibold"
                    color="_lightPurple"
                  >
                    15% URU
                    <Box
                      tag="img"
                      src={SupportFlagUruImg}
                      transform="translateY(20%)"
                      width={{ mobile: '18px', desktop: '35px' }}
                      height={{ mobile: '18px', desktop: '35px' }}
                    />
                  </Copy>
                </Box>
                <Box
                  paddingY="xsmall"
                  textAlign="center"
                  borderRadius="xxlarge"
                  backgroundColor="white"
                  width={{ mobile: '107px', desktop: '205px' }}
                  height={{ mobile: '32px', desktop: '56px' }}
                >
                  <Copy
                    fontSize="supportPercentPill"
                    fontWeight="semibold"
                    color="_lightPurple"
                  >
                    10% ARG
                    <Box
                      tag="img"
                      src={SupportFlagArgImg}
                      transform="translateY(20%)"
                      width={{ mobile: '18px', desktop: '35px' }}
                      height={{ mobile: '18px', desktop: '35px' }}
                    />
                  </Copy>
                </Box>
              </HStack>
            )}

            {isMx && (
              <HStack justifyContent="space-evenly" alignItems="center">
                <Box
                  paddingY="xsmall"
                  textAlign="center"
                  borderRadius="xxlarge"
                  backgroundColor="white"
                  width={{ mobile: '253px', desktop: '433px' }}
                  height={{ mobile: '32px', desktop: '56px' }}
                >
                  <Copy
                    fontSize="supportPercentPill"
                    fontWeight="semibold"
                    color="_lightPurple"
                  >
                    100% ESTADOS UNIDOS
                    <Box
                      tag="img"
                      src={SupportFlagUsaImg}
                      transform="translateY(20%)"
                      width={{ mobile: '18px', desktop: '35px' }}
                      height={{ mobile: '18px', desktop: '35px' }}
                    />
                  </Copy>
                </Box>
              </HStack>
            )}
            <Box
              marginBottom="xlarge"
              width={{ mobile: '326', desktop: '560' }}
            >
              <Copy fontSize="supportPercentUnderPill" textAlign="center">
                ubicación de las propiedades
              </Copy>
            </Box>
            <HStack space="small" alignItems="center">
              <Box
                paddingX="small"
                paddingY="small"
                textAlign="center"
                borderRadius="xxlarge"
                backgroundColor="white"
                width="600px"
              >
                <Copy
                  fontSize="supportPercentPill"
                  fontWeight="semibold"
                  color="_lightPurple"
                >
                  +90% USUARIOS
                </Copy>
              </Box>
              <Box
                paddingX="large"
                paddingY="small"
                textAlign="center"
                backgroundColor="white"
                borderRadius="xxlarge"
                width="470px"
              >
                <Copy
                  fontSize="supportPercentPill"
                  fontWeight="semibold"
                  color="_lightPurple"
                >
                  +17 MESES
                </Copy>
              </Box>
            </HStack>

            <HStack>
              <Box
                textAlign="center"
                width="500px"
                padding="xsmall"
                marginTop="xsmall"
                marginBottom="xsmall"
              >
                <Copy fontSize="supportPercentUnderPill">
                  reinvierte sus ganancias
                </Copy>
              </Box>
              <Box
                textAlign="center"
                width="420px"
                padding="xsmall"
                paddingRight="xsmall"
                marginTop="xsmall"
                marginBottom="xsmall"
              >
                <Copy fontSize="supportPercentUnderPill">
                  las personas se quedan en Simplestate
                </Copy>
              </Box>
            </HStack>
          </VStack>
          {isMobile && (
            <Box
              alignItems="middle"
              tag="img"
              marginTop="0px"
              src={SupportMobileImg}
              transform="translate(5%,11.5%)"
              width="340px"
              height="405px"
            />
          )}
        </StackDirection>
      </Box>
    </SectionLayout>
  );
};
export default WhySimplestate;
