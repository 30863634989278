import React, { useEffect } from 'react';
import { Box } from './SimpleUI';
import styled from 'styled-components';
import { useLocation } from 'react-use';

const Container = styled(Box)`
  position: absolute;
  height: 80px;
  bottom: 0;
  min-width: 60px;
  right: 20px;
`;

const Link = styled.a`
  max-width: 300px;
  overflow: hidden;
  transition: all 0.4s ease-out;
  min-width: 60px;
  height: 60px;
  cursor: pointer;
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 15;
  & span {
    position: relative;
    top: 19px;
    font-size: 16px;
    color: #fff;
    font-weight: 500;
    display: block;
    float: left;
    box-sizing: border-box;
    padding-left: 60px;
    padding-right: 20px;
    transition: all 0.3s ease;
  }
`;

const Logo = styled.img`
  position: absolute;
  transition: all 0.3s ease;
  opacity: 1;
  width: 100%;
  height: 100%;
`;

const WhatsappButton = () => {
  const { pathname } = useLocation();

  var getScript = document.getElementById('wcx-chat');
  const script = document.createElement('script');
  script.type = 'text/javascript';
  script.async = 1;
  script.src =
    'https://api.wcx.cloud/widget/?id=7b0a80bbbe444a02ba644de1c1c328c0';
  document.body.appendChild(script);

  useEffect(() => {
    if (pathname.includes('/ar')) {
      setTimeout(() => {
        try {
          document.getElementById('whatsapp-icon').style.display = 'none';
          document.getElementById('wcx-chat').style.display = 'block';
        } catch {}
      }, 1000);
    } else {
      setTimeout(() => {
        try {
          document.getElementById('wcx-chat').style.display = 'none';
          document.getElementById('whatsapp-icon').style.display = 'block';
        } catch {}
      }, 1000);
    }
    return () => {
      document.body.removeChild(script);
    };
  }, [pathname, getScript, script]);

  return (
    <>
      <Container id="whatsapp-icon" isAr={pathname.includes('/ar')}>
        <Link
          className="right"
          href="https://api.whatsapp.com/send?phone=5215561060506&text=Hola%20me%20gustar%C3%ADa%20invertir%20con%20ustedes"
          target="_blank"
          without
          rel="noreferrer"
        >
          <Logo src="https://www.pinclipart.com/picdir/big/580-5809783_whatsapp-messaging-apps-android-whatsapp-icon-vector-png.png" />
        </Link>
      </Container>
    </>
  );
};

export default WhatsappButton;
