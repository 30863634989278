import React from 'react';
import {
  Box,
  VStack,
  Container,
  Heading,
  TertiaryButton,
  WrapItem,
  WhiteOutline,
  HStack,
  Copy,
} from './SimpleUI';
import styled from 'styled-components';
import { Cell, Grid } from '@mollycule/lattice';
import { useHistory, Link } from 'react-router-dom';
import { ReactComponent as Twitter } from '../assets/images/twitter_new.svg';
import FooterNav from './FooterNav';
import {
  FaFacebookF,
  FaInstagram,
  FaLinkedinIn,
  FaYoutube,
  FaTiktok,
} from 'react-icons/fa';
import useAuthRecoil from '../hooks/useAuthRecoil';
import useDesignUtils from '../hooks/useDesignUtils';
import { useCountry } from '../contexts/CountryContext';
import { useTranslation } from 'react-i18next';

const Footer = () => {
  const history = useHistory();
  const { isMobile } = useDesignUtils();
  const { countryPrefix } = useCountry();
  const { t } = useTranslation();

  const isMetasUrl = history.location.pathname === '/metas';

  const {
    auth: { isAuth },
  } = useAuthRecoil();

  const fullFooter1 = [
    {
      to: `${countryPrefix}/productos`,
      label: !isMetasUrl && countryPrefix === '/mx' ? 'Producto' : 'Productos',
    },
    {
      to: `${countryPrefix}/simple-academy`,
      label: 'Simple Academy',
    },
    {
      to: `${countryPrefix}/preguntas-frecuentes`,
      label: 'Preguntas Frecuentes',
    },
    {
      to: `${countryPrefix}/nosotros`,
      label: 'Nosotros',
    },
    {
      to: `${countryPrefix}/empleos`,
      label: 'Empleos',
    },
    // Comentado porque todavia no tenemos ninguna empresa para mostrar
    // {
    //   to: `${countryPrefix}/empresas`,
    //   label: 'Empresas',
    // },
  ];

  const footer2 = [
    {
      to: `${countryPrefix}/politica-privacidad`,
      label: 'Aviso de privacidad',
    },
    {
      to: `${countryPrefix}/terminos-y-condiciones`,
      label: 'Términos y condiciones',
    },
  ];

  const socialnetworks = [
    {
      icon: <FaYoutube size={24} color="white" />,
      url: 'https://www.youtube.com/channel/UCnQwt2GhwFHQlTA3BdHnbyA',
    },
    {
      icon: <FaInstagram size={24} color="white" />,
      url:
        countryPrefix === '/mx'
          ? 'https://www.instagram.com/se_simplestate_mx/'
          : 'https://www.instagram.com/se_simplestate/?hl=es-la',
    },
    {
      icon: <Twitter width="22px" />,
      url: 'https://twitter.com/se_simplestate',
    },
    {
      icon: <FaLinkedinIn size={24} color="white" />,
      url: 'https://www.linkedin.com/company/se-simplestate/',
    },
    {
      icon: <FaTiktok size={24} color="white" />,
      url: 'https://www.tiktok.com/@se_simplestate',
    },
    {
      icon: <FaFacebookF size={24} color="white" />,
      url: 'https://www.facebook.com/profile.php?id=61555544918113',
    },
  ];

  const footer1 =
    countryPrefix === '/mx' ? fullFooter1.slice(0, -1) : fullFooter1;

  return (
    <Box tag="footer" backgroundColor="purple" paddingY="xxlarge">
      <Container>
        <Grid
          width="100%"
          mx="auto"
          gap="24px"
          cols={{ md: 12, sm: 12, xs: 12 }}
        >
          <Cell x-span={{ md: '4', sm: '4', xs: '12' }} x-offset={{ md: '2' }}>
            <VStack space="medium">
              <Box maxWidth="300px">
                <Heading tag="h5" color="white" fontWeight="medium">
                  {t('footer.title')}
                </Heading>
              </Box>

              <HStack space="large">
                <Box width="136px" height="40px">
                  <WhiteOutline
                    type="button"
                    size="full"
                    onClick={() => history.push(`${countryPrefix}/cuenta`)}
                  >
                    Iniciar sesión
                  </WhiteOutline>
                </Box>
                <Box width="136px" height="40px">
                  <TertiaryButton
                    type="button"
                    size="full"
                    backgroundColor="white"
                    borderColor="white"
                    color="link"
                    hover=""
                    onClick={() => {
                      history.push(`${countryPrefix}/cuenta/crear-cuenta`);
                    }}
                  >
                    Crear cuenta
                  </TertiaryButton>
                </Box>
              </HStack>
            </VStack>
          </Cell>
          {!isMobile && (
            <Cell x-span={{ md: '2', sm: '2', xs: '6' }} x-offset={{ md: '6' }}>
              <VStack space="small">
                <Box maxWidth={{ mobile: '100%', desktop: '153px' }}>
                  <Grid
                    width="100%"
                    display="flex"
                    justifyContent="center"
                    cols={{ sm: 12, md: 12, xs: 12 }}
                  >
                    <Cell x-span={{ sm: 12, md: 12 }}>
                      <Copy
                        tag="h5"
                        color="white"
                        fontWeight="medium"
                        textTransform="uppercase"
                      >
                        REDES SOCIALES
                      </Copy>
                      <Box height="18px" />
                    </Cell>

                    {socialnetworks.map((item, index) => (
                      <Cell key={index} x-span={{ sm: 3, md: 4 }}>
                        <WrapItem marginX="none">
                          <a
                            href={item.url}
                            target="_blank"
                            rel="nofollow noopener noreferrer"
                          >
                            {item.icon}
                          </a>
                        </WrapItem>
                      </Cell>
                    ))}
                  </Grid>
                </Box>
              </VStack>
            </Cell>
          )}
          <Cell x-span={{ md: '2', sm: '2', xs: '6' }} x-offset={{ md: '8' }}>
            <VStack space="medium">
              <FooterNav title="SIMPLESTATE" options={footer1} />
            </VStack>
          </Cell>
          <Cell x-span={{ md: '2', sm: '2', xs: '6' }} x-offset={{ md: '10' }}>
            <VStack space="medium">
              <FooterNav title="LEGALES" options={footer2} />
              {isMobile && (
                <HStack space="small">
                  <Box maxWidth={{ mobile: '100%', desktop: '153px' }}>
                    <Grid
                      width="100%"
                      display="flex"
                      justifyContent="center"
                      cols={{ sm: 12, md: 12, xs: 12 }}
                    >
                      <Cell x-span={{ sm: 12, md: 12, xs: 12 }}>
                        <Copy
                          tag="h5"
                          color="white"
                          fontWeight="medium"
                          textTransform="uppercase"
                        >
                          REDES SOCIALES
                        </Copy>
                        <Box height="18px" />
                      </Cell>

                      {socialnetworks.map((item, index) => (
                        <Cell key={index} x-span={{ sm: 3, md: 4 }}>
                          <WrapItem marginX="none">
                            <a
                              href={item.url}
                              target="_blank"
                              rel="nofollow noopener noreferrer"
                            >
                              {item.icon}
                            </a>
                          </WrapItem>
                        </Cell>
                      ))}
                    </Grid>
                  </Box>
                </HStack>
              )}
            </VStack>
          </Cell>

          <Cell
            x-span={{ md: '10', sm: '10', xs: '12' }}
            x-offset={{ md: '2' }}
          >
            <hr />
          </Cell>
          <Cell
            x-span={{ md: '10', sm: '10', xs: '12' }}
            x-offset={{ md: '2' }}
          >
            <VStack space="medium">
              <Copy
                fontSize="footer"
                color="white"
                lineHeight="24.697px"
                fontWeight="normal"
              >
                {' '}
                {t('footer.terms.title')}
              </Copy>
              <Copy
                fontSize="footer"
                color="white"
                lineHeight="24.697px"
                fontWeight="normal"
              >
                {t('footer.terms.description')}
              </Copy>
              <Copy
                fontSize="footer"
                color="white"
                lineHeight="24.697px"
                fontWeight="normal"
              >
                {t('footer.terms.footerText')}
              </Copy>
            </VStack>
          </Cell>
        </Grid>
      </Container>
    </Box>
  );
};

const FooterSeparator = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  opacity: 0.7;
  height: 2px;
  width: 100%;
`;

const FooterInfo = styled.div`
  p {
    opacity: 0.5;
  }
`;

export default Footer;
