import React from 'react';
import { Box, VStack, Copy, Title, SimpleCarousel } from '../../../SimpleUI';

import { useCountry } from '../../../../contexts/CountryContext';
import useDesignUtils from '../../../../hooks/useDesignUtils';
import SectionLayout from '../../../Home/SectionLayout';

import SupportNotesMainCronistaImg from '../../../../assets/images/support-notes-main-elcronista.png';
import SupportNotesMainClarinImg from '../../../../assets/images/support-notes-main-clarin.png';
import SupportNotesMainIProupImg from '../../../../assets/images/support-notes-main-iproup.png';
import SupportNotesMainIProfesionalImg from '../../../../assets/images/support-notes-main-iprofesional.png';
import SupportNotesMainForbesImg from '../../../../assets/images/support-notes-main-forbes.png';
import SupportNotesMainBloombergImg from '../../../../assets/images/support-notes-main-bloomberg.png';
import SupportNotesMainInfobaeImg from '../../../../assets/images/support-notes-main-infobae.png';
import SupportNotesMainCointelegraphImg from '../../../../assets/images/support-notes-main-cointelegraph.png';

import SupportNotesLogoCronistaImg from '../../../../assets/images/support-notes-logo-el-cronista.svg';
import SupportNotesLogoClarinImg from '../../../../assets/images/support-notes-logo-clarin.svg';
import SupportNotesLogoIProupImg from '../../../../assets/images/support-notes-logo-iproup.svg';
import SupportNotesLogoIProfesionalImg from '../../../../assets/images/support-notes-logo-iprofesional.svg';
import SupportNotesLogoForbesImg from '../../../../assets/images/support-notes-logo-forbes.svg';
import SupportNotesLogoBloombergImg from '../../../../assets/images/support-notes-logo-boomberg.svg';
import SupportNotesLogoInfobaeImg from '../../../../assets/images/support-notes-logo-infobae.svg';
import SupportNotesLogoCointelegraphImg from '../../../../assets/images/support-notes-logo-cointelegraph.svg';

import SupportNotesMainExpansionImg from '../../../../assets/images/support-notes-main-expansion.png';
import SupportNotesMainMexicoBusinessImg from '../../../../assets/images/support-notes-main-mexico-business.png';
import SupportNotesMainRealStateImg from '../../../../assets/images/support-notes-main-real-state.png';
import SupportNotesMainMundoEjecutivoImg from '../../../../assets/images/support-notes-main-mundo-ejecutivo.png';
import SupportNotesMainEconomistaImg from '../../../../assets/images/support-notes-main-economista.png';
import SupportNotesMainEnConcretoImg from '../../../../assets/images/support-notes-main-en-concreto.png';
import SupportNotesMainCioImg from '../../../../assets/images/support-notes-main-cio.png';

import SupportNotesLogoExpansionImg from '../../../../assets/images/support-notes-logo-expansion.svg';
import SupportNotesLogoMexicoBusinessImg from '../../../../assets/images/support-notes-logo-mexico-bussiness.svg';
import SupportNotesLogoRealStateImg from '../../../../assets/images/support-notes-logo-real-estate.svg';
import SupportNotesLogoMundoEjecutivoImg from '../../../../assets/images/support-notes-logo-mundo-ejecutivo.svg';
import SupportNotesLogoEconomistaImg from '../../../../assets/images/support-notes-logo-el-economista.svg';
import SupportNotesLogoEnConcretoImg from '../../../../assets/images/support-notes-logo-en-concreto.svg';
import SupportNotesLogoCioImg from '../../../../assets/images/support-notes-logo-co.svg';

const notesAr = [
  {
    id: 1,
    portal: 'Infobae',
    note: 'Oportunidades de inversión: cuál es la plataforma que facilita el acceso al real estate con bajos montos en pesos',
    cover: SupportNotesMainInfobaeImg,
    logo: SupportNotesLogoInfobaeImg,
    link: `https://www.infobae.com/inhouse/2023/12/28/oportunidades-de-inversion-cual-es-la-plataforma-que-facilita-el-acceso-al-real-estate-con-bajos-montos-en-pesos/`,
  },
  {
    id: 2,
    portal: 'Clarín',
    note: 'Invertir tus pesos hoy: las 10 preguntas clave que debés hacerte antes de decidir',
    cover: SupportNotesMainClarinImg,
    logo: SupportNotesLogoClarinImg,
    link: `https://www.clarin.com/servicios/invertir-pesos-hoy-10-preguntas-clave-debes-hacerte-decidir_0_JXCZzmpC90.html`,
  },
  {
    id: 3,
    portal: 'Forbes',
    note: 'Invertir en el contexto económico actual: las diez preguntas que debés hacerte antes de tomar una decisión',
    cover: SupportNotesMainForbesImg,
    logo: SupportNotesLogoForbesImg,
    link: `https://www.forbesargentina.com/money/invertir-contexto-economico-actual-diez-preguntas-debes-hacerte-antes-tomar-una-decision-n48400`,
  },
  {
    id: 4,
    portal: 'El Cronista',
    note: 'Chau Mercado Pago: cómo es la primera "billetera virtual" de propiedades en la Argentina',
    cover: SupportNotesMainCronistaImg,
    logo: SupportNotesLogoCronistaImg,
    link: `https://www.cronista.com/infotechnology/actualidad/chau-mercado-pago-como-es-la-primera-billetera-virtual-de-propiedades-en-la-argentina/`,
  },
  {
    id: 5,
    portal: 'Iproup',
    note: 'Así funciona la wallet de propiedades que revoluciona el mercado inmobiliario latinoamericano',
    cover: SupportNotesMainIProupImg,
    logo: SupportNotesLogoIProupImg,
    link: `https://www.iproup.com/index.php/economia-digital/47215-la-billetera-virtual-de-propiedades-que-revoluciona-el-mercado-inmobiliario`,
  },
  {
    id: 6,
    portal: 'Iprofesional',
    note: 'Es posible invertir en el mercado inmobiliario de EE.UU. y Europa en pesos y desde Argentina: así funciona el crowdfunding',
    cover: SupportNotesMainIProfesionalImg,
    logo: SupportNotesLogoIProfesionalImg,
    link: `https://www.iprofesional.com/negocios/400333-como-ganar-dolares-invirtiendo-desde-5-000-pesos-en-propiedades-del-exterior`,
  },
  {
    id: 7,
    portal: 'Cointelegraph',
    note: 'Expertos alertan sobre la preocupante falta de educación financiera en Argentina',
    cover: SupportNotesMainCointelegraphImg,
    logo: SupportNotesLogoCointelegraphImg,
    link: `https://es.cointelegraph.com/news/experts-warn-about-the-worrying-lack-of-financial-education-in-argentina`,
  },
  {
    id: 8,
    portal: 'Bloomberg',
    note: 'Radiografía del inversor millennial en Latinoamérica',
    cover: SupportNotesMainBloombergImg,
    logo: SupportNotesLogoBloombergImg,
    link: `https://www.bloomberglinea.com/2022/10/26/radiografia-del-inversor-millenial-en-latinoamerica/`,
  },
];

const notesMx = [
  {
    id: 1,
    portal: 'Expansion',
    note: '¿Tienes 100 pesos?, inviértelos en bienes raíces',
    cover: SupportNotesMainExpansionImg,
    logo: SupportNotesLogoExpansionImg,
    link: `https://expansion.mx/finanzas-personales/2024/02/09/como-invertir-bienes-raices-con-poco-dinero`,
  },
  {
    id: 2,
    portal: 'Mexico Business',
    note: 'Simplestate Expands Real Estate Investment Services to Mexico',
    cover: SupportNotesMainMexicoBusinessImg,
    logo: SupportNotesLogoMexicoBusinessImg,
    link: `https://mexicobusiness.news/finance/news/simplestate-expands-real-estate-investment-services-mexico`,
  },
  {
    id: 3,
    portal: 'Real Estate',
    note: 'Transformando el mercado inmobiliario, el rol crucial de la IA',
    cover: SupportNotesMainRealStateImg,
    logo: SupportNotesLogoRealStateImg,
    link: `https://www.realestatemarket.com.mx/noticias/mercado-inmobiliario/44953-transformando-el-mercado-inmobiliario-el-rol-crucial-de-la-ia`,
  },
  {
    id: 4,
    portal: 'Mundo Ejecutivo',
    note: '5 formas en que la inteligencia artificial está transformando el mercado inmobiliario ',
    cover: SupportNotesMainMundoEjecutivoImg,
    logo: SupportNotesLogoMundoEjecutivoImg,
    link: `https://mundoejecutivo.com.mx/mujer-ejecutiva/5-formas-en-que-la-inteligencia-artificial-esta-transformando-el-mercado-inmobiliario/`,
  },
  {
    id: 5,
    portal: 'El Economista',
    note: 'Simplestate, la plataforma promete inversiones inmobiliarias desde 100 pesos en Estados Unidos ',
    cover: SupportNotesMainEconomistaImg,
    logo: SupportNotesLogoEconomistaImg,
    link: `https://www.eleconomista.com.mx/econohabitat/Simplestate-la-plataforma-promete-inversiones-inmobiliarias-desde-100-pesos-en-Estados-Unidos-20240208-0141.html`,
  },
  {
    id: 6,
    portal: 'En Concreto',
    note: 'Educación financiera para incentivar inversión: Especialistas',
    cover: SupportNotesMainEnConcretoImg,
    logo: SupportNotesLogoEnConcretoImg,
    link: `https://grupoenconcreto.com/noticias/educacion-financiera-para-incentivar-inversion-especialistas/`,
  },
  {
    id: 7,
    portal: 'CIO Noticias',
    note: 'Simplestate llega a México para abrir las puertas a las inversiones inmobiliarias',
    cover: SupportNotesMainCioImg,
    logo: SupportNotesLogoCioImg,
    link: `https://cionoticias.tv/2024/02/05/simplestate-llega-a-mexico-para-abrir-las-puertas-a-las-inversiones-inmobiliarias/`,
  },
];

const Media = () => {
  const { isMobile } = useDesignUtils();
  const { countryPrefix } = useCountry();

  const notes = countryPrefix === '/mx' ? notesMx : notesAr;

  return (
    <SectionLayout backgroundColor="white">
      <Box
        width="100%"
        paddingX="medium"
        maxWidth="1200px"
        paddingY={{ mobile: 'medium', desktop: 'large' }}
        marginY={{ mobile: 'medium', desktop: 'large' }}
        paddingTop={{ mobile: 'medium', desktop: 'xxxxxlarge' }}
        paddingBottom={{ mobile: 'medium', desktop: 'xsmall' }}
        margin="auto"
      >
        <Box marginY="large">
          <Title fontSize="bigHeader" textAlign="center">
            Simplestate en los medios
          </Title>
        </Box>
        <Box marginY="large">
          <Copy fontSize="textHeading" textAlign="center">
            Simplestate captura la atención de los principales medios de LATAM.
          </Copy>
        </Box>
        <SimpleCarousel
          dots={true}
          arrows={false}
          centerMode={false}
          width={{ mobile: '350px', desktop: '1162px' }}
          height={{ mobile: 'small', desktop: '405px' }}
          infinite={false}
          initialSlide={0}
          slidesToShow={isMobile ? 1 : 3}
        >
          {notes.map((note, i) => {
            return (
              <Box
                key={i}
                marginLeft={{ mobile: 'xsmall', desktop: 'xsmall' }}
                marginRight={{ mobile: 'xsmall', desktop: 'xsmall' }}
                paddingLeft={{ mobile: 'xsmall', desktop: 'xlarge' }}
                paddingRight={{ mobile: 'xsmall', desktop: 'xlarge' }}
                marginTop={{ mobile: 'small', desktop: 'none' }}
                paddingTop={{ mobile: 'small', desktop: 'none' }}
                marginBottom={{ mobile: 'small', desktop: 'none' }}
                paddingBottom={{ mobile: 'small', desktop: 'none' }}
                tag="div"
                cursor="pointer"
                width={{ mobile: '350px', desktop: '854px' }}
                height={{ mobile: '342px', desktop: '490px' }}
                minHeight="400px"
                onClick={() => {
                  window.location.href = note.link;
                }}
              >
                <VStack space="xlarge">
                  <Box
                    tag="img"
                    src={note.cover}
                    link={note.link}
                    width={{ mobile: '552px', desktop: '354px' }}
                    height={{ mobile: '206px', desktop: '210px' }}
                  />

                  <Box
                    tag="div"
                    justifyContent={{ mobile: 'center', desktop: 'left' }}
                  >
                    <Box
                      tag="img"
                      src={note.logo}
                      paddingBottom="large"
                      alignItems="right"
                      maxHeight={{ mobile: '50px', desktop: '70px' }}
                      objectFit="fill"
                    />
                    <Copy
                      fontSize="supportMediaCardTitle"
                      fontWeight="semibold"
                      tag="p"
                      paddingX="middle"
                      textAlign="left"
                      lineHeight="26px"
                    >
                      {note.note}
                    </Copy>
                  </Box>
                </VStack>
              </Box>
            );
          })}
        </SimpleCarousel>
      </Box>
    </SectionLayout>
  );
};
export default Media;
