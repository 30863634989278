import { gql } from '@apollo/client';

export const LIKE_PROJECT = gql`
  mutation projectLike($id: Float!) {
    projectLike(idProject: $id, type: LIKE) {
      id
      like
      name
    }
  }
`;

export const DISLIKE_PROJECT = gql`
  mutation projectDisLike($id: Float!) {
    projectLike(idProject: $id, type: UN_LIKE) {
      id
      like
      name
    }
  }
`;

export const BASIC_SIGN_IN = gql`
  mutation SignIn($email: String!, $password: String, $country: Float) {
    login(
      email: $email
      password: $password
      provider: BASIC
      country: $country
    ) {
      accessToken
      tokenType
      expiresIn
      provider
    }
  }
`;

export const GOOGLE_SING_IN = gql`
  mutation SignIn(
    $token: String
    $email: String!
    $cupon: String
    $gclid: String
    $country: Float
  ) {
    login(
      token: $token
      email: $email
      provider: GOOGLE
      cupon: $cupon
      gclid: $gclid
      country: $country
    ) {
      accessToken
      tokenType
      expiresIn
      provider
      country
    }
  }
`;

export const FACEBOOK_SIGN_IN = gql`
  mutation SignIn(
    $token: String
    $email: String!
    $cupon: String
    $gclid: String
  ) {
    login(
      token: $token
      email: $email
      provider: FACEBOOK
      cupon: $cupon
      gclid: $gclid
    ) {
      accessToken
      tokenType
      expiresIn
      provider
    }
  }
`;

export const SIGN_UP = gql`
  mutation signUp($user: AddBasicRegister!) {
    basicRegister(user: $user) {
      name
      token
    }
  }
`;

export const FULL_REGISTER = gql`
  mutation fullRegister($user: AddFullRegister!) {
    fullRegister(user: $user) {
      id
    }
  }
`;

export const CONTACT_FORM_INFO = gql`
  mutation contactRequest($contactRequest: ContactRequest!) {
    contactRequest(contactRequest: $contactRequest) {
      message
    }
  }
`;

export const SEND_NEWSLETTER_FORM = gql`
  mutation newsLetterSubscribe($suscribe: NewsLetterRequest!) {
    newsLetterSubscribe(suscribe: $suscribe) {
      message
    }
  }
`;

export const VALIDATE_USER_EMAIL = gql`
  mutation verifyEmail($verificationData: VerifyEmailInput!) {
    verifyEmail(verificationData: $verificationData) {
      success
      message
      id
    }
  }
`;

export const CREATE_INVESTMENT = gql`
  mutation createInvestment($investmentInput: InvestmentInput!) {
    createInvestment(investmentInput: $investmentInput) {
      message
      id
      couponAmount
    }
  }
`;

export const RESEND_VERIFICATION_EMAIL = gql`
  mutation resendVerifyEmail(
    $resendVerificationEmailData: VerifyEmailResendInput!
  ) {
    resendVerifyEmail(
      resendVerificationEmailData: $resendVerificationEmailData
    ) {
      message
    }
  }
`;

export const CREATE_BANK_ACCOUNT = gql`
  mutation createBankAccount($bankRequest: AddBankAccount!) {
    createBankAccount(bankRequest: $bankRequest) {
      message
    }
  }
`;

export const EDIT_BANK_ACCOUNT = gql`
  mutation editBankAccount($bankRequest: EditBankAccount!) {
    editBankAccount(bankRequest: $bankRequest) {
      message
    }
  }
`;

export const MAKE_PAY = gql`
  mutation makePayment(
    $paymentDetails: PaymentRequestNew!
    $projectId: Float!
  ) {
    makePaymentNew(paymentDetails: $paymentDetails, projectId: $projectId) {
      id
      message
      urlReturn
    }
  }
`;

export const CREATE_INVESTMENT_FLEX = gql`
  mutation flexInvestment($investment: InvestFlex!) {
    flexInvestment(investment: $investment) {
      success
      message
    }
  }
`;

export const FORGOT_PASSWORD = gql`
  mutation forgotPassword($forgotPasswordData: ForgotPasswordInput!) {
    forgotPassword(forgotPasswordData: $forgotPasswordData) {
      id
      message
    }
  }
`;

export const RESET_PASSWORD = gql`
  mutation resetPassword($resetPasswordData: ResetPasswordInput!) {
    resetPassword(resetPasswordData: $resetPasswordData) {
      message
      id
    }
  }
`;

export const WITHDRAW_FUNDS = gql`
  mutation withdrawFunds($withdrawFundsInput: WithdrawFundsInput!) {
    withdrawFunds(withdrawFundsInput: $withdrawFundsInput) {
      message
      id
    }
  }
`;

export const WITHDRAW_FUNDS_FLEX = gql`
  mutation withdrawFundsFlex($withdrawFundsInput: WithdrawFundsInput!) {
    withdrawFundsFlex(withdrawFundsInput: $withdrawFundsInput) {
      message
      success
    }
  }
`;

export const EMPOWER_FUNDS_FLEX = gql`
  mutation empowerFundsFlex($empowerFundsInput: EmpowerFundsInput!) {
    empowerFundsFlex(empowerFundsInput: $empowerFundsInput) {
      message
      success
    }
  }
`;

export const CHANGE_PASSWORD = gql`
  mutation changePassword($changePasswordData: ChangePasswordInput!) {
    changePassword(changePasswordData: $changePasswordData) {
      message
      id
    }
  }
`;

export const REQUEST_CONTRACT = gql`
  mutation downloadContract($investmentId: Float!) {
    downloadContract(investmentId: $investmentId) {
      message
      data
      success
    }
  }
`;

export const CREATE_REFERRER = gql`
  mutation createCoupon($newReferrer: CouponInput!) {
    createCoupon(newReferrer: $newReferrer) {
      message
      success
    }
  }
`;

export const CREATE_PROMO_COUPON = gql`
  mutation createPromoCoupon($couponArg: CouponInput!) {
    createPromoCoupon(couponArg: $couponArg) {
      success
      message
    }
  }
`;

export const IMAGE_REGISTER = gql`
  mutation imageRegister($documents: AddImageRegister!) {
    imageRegister(documents: $documents) {
      dniFront
      dniBack
      selfie
      pep
    }
  }
`;

export const UPLOAD_VOUCHER = gql`
  mutation imageComprobante($documents: AddVoucher!) {
    imageComprobante(documents: $documents) {
      success
      message
    }
  }
`;

export const UPLOAD_VOUCHER_FONDEO = gql`
  mutation imageComprobanteFondeo($documents: AddVoucherFondeo!) {
    imageComprobanteFondeo(documents: $documents) {
      success
      message
    }
  }
`;

export const VALIDATE_USER = gql`
  mutation validateUser($user: ValidateUser!) {
    validateUser(user: $user) {
      success
      message
    }
  }
`;

export const EDIT_PROFILE = gql`
  mutation editUserProfile($editUser: EditUserProfile!) {
    editUserProfile(editUser: $editUser) {
      success
      message
      id
    }
  }
`;

export const SET_HIZO_LOGIN = gql`
  mutation setHizoLogin($hizoLoginData: SetHizoLogin!) {
    setHizoLogin(hizoLoginData: $hizoLoginData) {
      id
      message
      success
    }
  }
`;

export const SET_DEFAULT_BANK_ACCOUNT = gql`
  mutation setBankAccount($id: Float!) {
    setBankAccount(id: $id) {
      message
    }
  }
`;

export const UPDATE_NOTIFICATION = gql`
  mutation updateNotifications($updateNotif: NotificationsInput!) {
    updateNotifications(updateNotif: $updateNotif) {
      success
      message
    }
  }
`;

export const DELETE_ACCOUNT_BANK = gql`
  mutation deleteBankAccount($id: Float!) {
    deleteBankAccount(id: $id) {
      message
    }
  }
`;

export const CANCEL_INVESTMENT = gql`
  mutation cancelInvestment($investmentId: Float!) {
    cancelInvestment(investmentId: $investmentId) {
      message
    }
  }
`;

export const SIMULATE_PROJECTS = gql`
  mutation simulateProjects($proyects_id: [SimulatorRequest!]!) {
    simulateProjects(proyects_id: $proyects_id) {
      message
      data {
        id
        name
        slug
        profitability {
          text
          value
        }
        terms {
          text
          value
        }
        investmentType {
          id
          description
        }
        calculator {
          texto
          cotizacion_dolar
          ganancia_total
        }
        img {
          foto_slider
        }
      }
    }
  }
`;

export const CHARGE_BALANCE = gql`
  mutation chargeBalance($chargeBalanceInput: ChargeBalanceInput!) {
    chargeBalance(chargeBalanceInput: $chargeBalanceInput) {
      message
      success
      data {
        id
        url_pago
      }
    }
  }
`;

export const INVEST_PROJECT_DASHBOARD = gql`
  mutation getAllModelsInvest($investmentInput: InvestmentAllModelsInput!) {
    getAllModelsInvest(investmentInput: $investmentInput) {
      success
      message
      data {
        id
        investmentType {
          id
          description
        }
        bankAccounts {
          businessName
          cuit
          bank
          accountType
          accountNumber
          cbu
          additionalInfo
          accountTypeId
        }
        name
        anualProfit
        finalTerm
        finalProfitability
        properties {
          availability {
            id
            months
            amounts
            inversion_min
            inversion_max
          }
          paymentMethods {
            id
            name
          }
        }
        projectedProfit {
          text
          dollarQuotation
          dateEnd
          totalProfit
          monthlyProfit
          annualProfit
        }
        paymentMethods {
          id
          name
        }
        image {
          photoHeader
          photosSlider
        }
        terms {
          value
          text
        }
        address {
          locality
          province
        }
        design
      }
    }
  }
`;

export const INVEST_PROJECT_BY_TYPE = gql`
  mutation getModelInvestByType($investmentInput: InvestmentModelInput!) {
    getModelInvestByType(investmentInput: $investmentInput) {
      message
      data {
        id
        investmentType {
          id
          description
        }
        name
        anualProfit
        projectedProfit {
          text
          dollarQuotation
          dateEnd
          totalProfit
        }
        terms {
          value
          text
        }
        inversionLimits {
          minUSD
          minARS
          maxUSD
          maxARS
        }
        properties {
          paymentMethods {
            id
            name
          }
        }
        currentValue
      }
    }
  }
`;

export const MAKE_PAY_DASHBOARD = gql`
  mutation makeInvestmentsAllModels($investmentInput: MakePaymentInvest!) {
    makeInvestmentsAllModels(investmentInput: $investmentInput) {
      message
      success
      data {
        id
        url_pago
      }
    }
  }
`;

export const WITHDRAW_FUNDS_PROJECT = gql`
  mutation withdrawFundsProjects($id: Float!) {
    withdrawFundsProjects(id: $id) {
      message
      success
    }
  }
`;

export const CONVERT_TO_PESOS = gql`
  mutation convertToPesos($convertToPesos: ConvertToPesosInput!) {
    convertToPesos(convertToPesos: $convertToPesos) {
      message
      success
    }
  }
`;

export const DELETE_GOAL = gql`
  mutation deleteGoal($id: Float!) {
    deleteGoal(id: $id) {
      message
      success
    }
  }
`;

export const CREATE_GOAL = gql`
  mutation createGoal($createGoal: GoalResolverInput!) {
    createGoal(createGoal: $createGoal) {
      message
      success
      data {
        id
      }
    }
  }
`;

export const PAYMENT_GOAL = gql`
  mutation paymentGoal($createGoal: GoalInvestmentInput!) {
    paymentGoal(createGoal: $createGoal) {
      message
      urlReturn
      inversionId
      metaId
      success
    }
  }
`;

export const GET_POST_CATEGORY = gql`
  mutation getPostByCategory($id: Float!, $page: Float!) {
    getPostByCategory(id: $id, page: $page) {
      success
      count
      data {
        id
        slug
        status
        type
        link
        title
        status
        content
        excerpt
        cover
      }
    }
  }
`;

export const WITHDRAW_FUNDS_MX = gql`
  mutation withdrawFundsProjectsMX($email: String!) {
    withdrawFundsProjectsMX(email: $email) {
      success
    }
  }
`;

export const GRAPHIC_SIMULATOR = gql`
  mutation investmentSimulator(
    $investmentSimulatorRequest: InvestmentSimulatorRequest!
  ) {
    investmentSimulator(
      investmentSimulatorRequest: $investmentSimulatorRequest
    ) {
      tasa
      ganancia_estimada
      slider {
        min
        max
        escala
        tipo_moneda
        monto
      }
      grafico {
        plazo {
          meses
          anios
        }
        tipo_moneda
        inversion_mensual
        ganancias {
          ganancia
          anio
          meses
        }
      }
    }
  }
`;

export const TOGGLE_AUTO_REINVEST = gql`
  mutation toggleAutoReinvest($toActive: Boolean!) {
    toggleAutoReinvest(toActive: $toActive) {
      message
      success
    }
  }
`;

export const CONFIRM_REINVEST = gql`
  mutation confirmReinvestment {
    confirmReinvestment {
      message
      success
      data
    }
  }
`;

export const BOOST = gql`
  mutation boost($inputGoal: InputGoal!) {
    boost(inputGoal: $inputGoal) {
      message
      success
      data {
        metaId
      }
    }
  }
`;
