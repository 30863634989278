import React from 'react';
import { Box, Copy, SimpleCarousel, Title, VStack } from '../../../SimpleUI';
import { PropertiesCarousel } from '../../../Product/SliderProject';
import useDesignUtils from '../../../../hooks/useDesignUtils';
import { useCountry } from '../../../../contexts/CountryContext';
import SectionLayout from '../../../Home/SectionLayout';

const photosSliderEnglewood = [
  'https://d9galtn46hi6.cloudfront.net/uploads/proyectos/fotos/1706196337_65b27d715f4a4.jpeg',
  'https://d9galtn46hi6.cloudfront.net/uploads/proyectos/fotos/1706196290_65b27d42abd49.jpeg',
  'https://d9galtn46hi6.cloudfront.net/uploads/proyectos/fotos/1706196383_65b27d9f0b561.jpeg',
  'https://simplestate-statics.s3.amazonaws.com/img/image+(6).png',
  'https://simplestate-statics.s3.amazonaws.com/img/image+(5).png',
];

const photosSliderCharlotte1 = [
  'https://d9galtn46hi6.cloudfront.net/uploads/proyectos/fotos/1705673858_65aa8482bf48b.jpg',
  'https://d9galtn46hi6.cloudfront.net/uploads/proyectos/fotos/1705673780_65aa84344c086.jpeg',
  'https://d9galtn46hi6.cloudfront.net/uploads/proyectos/fotos/1705673758_65aa841e24f13.jpeg',
];

const photosSliderPrinceton = [
  'https://simplestate-statics.s3.us-east-1.amazonaws.com/img/image_1.jpeg',
  'https://simplestate-statics.s3.us-east-1.amazonaws.com/img/image_2.jpeg',
  'https://simplestate-statics.s3.us-east-1.amazonaws.com/img/image_3.jpeg',
  'https://simplestate-statics.s3.us-east-1.amazonaws.com/img/image_4.jpeg',
  'https://d9galtn46hi6.cloudfront.net/uploads/proyectos/fotos/1699917858_6552b022e0c1c.jpg',
  'https://d9galtn46hi6.cloudfront.net/uploads/proyectos/fotos/1699917886_6552b03e1593b.jpg',
  'https://d9galtn46hi6.cloudfront.net/uploads/proyectos/fotos/1699918137_6552b139deaaa.jpg',
];

const photosMiami = [
  'https://simplestate-statics.s3.us-east-1.amazonaws.com/img/highland1.png',
  'https://simplestate-statics.s3.us-east-1.amazonaws.com/img/highland2.png',
  'https://simplestate-statics.s3.us-east-1.amazonaws.com/img/highland3.png',
  'https://simplestate-statics.s3.us-east-1.amazonaws.com/img/highland4.png',
  'https://simplestate-statics.s3.us-east-1.amazonaws.com/img/highlandlocation.png',
];

const photosSliderMontoya = [
  'https://front-uploads.s3.amazonaws.com/montoyaa1.jpeg',
  'https://front-uploads.s3.amazonaws.com/montoyaa2.jpeg',
  'https://front-uploads.s3.amazonaws.com/montoyaa3.jpeg',
  'https://front-uploads.s3.amazonaws.com/montoyaa4.jpeg',
];

const miamiProject = {
  title: 'Miami, Florida - EE.UU.',
  description:
    'Highland Parc es un desarrollo de 63 unidades en la zona de Overtown, Miami, apuntadas a satisfacer las necesidades de los inquilinos que buscan espacios de vida compartidos.',
  images: photosMiami,
};
const princetonProject = {
  title: 'Princeton - Florida, EE.UU.',
  description:
    'Es un complejo de Townhomes en la localidad de Princeton, Florida, EEUU.',
  images: photosSliderPrinceton,
};
const englewoodProject = {
  title: 'Englewood - Florida, EE.UU.',
  description:
    'Terreno en barrio de buena ubicación con el objetivo de construir un duplex y venderlo. El barrio en cuestión es Port Charlotte. Se construirá bajo lamodalidad de Wood Frame.',
  images: photosSliderEnglewood,
};
const portCharlotteProject = {
  title: 'Port Charlotte - Florida, EE.UU.',
  description:
    'Propiedad de alta categoría, con ambientes abiertos y gran luminosidad. Está ubicada en una zona premium de Florida.',
  images: photosSliderCharlotte1,
};
const montoyaProject = {
  title: 'Montoya, PdE - Uruguay',
  description:
    'Situado en el corazón de Montoya, a un paso del mar, ofrece amplios terrenos en un punto estratégico de notable crecimiento y desarrollo.',
  images: photosSliderMontoya,
};
const arProjects = [
  miamiProject,
  princetonProject,
  englewoodProject,
  portCharlotteProject,
  montoyaProject,
];
const mxProjects = [
  miamiProject,
  princetonProject,
  englewoodProject,
  portCharlotteProject,
];

const Projects = () => {
  const { isMobile } = useDesignUtils();
  const { countryPrefix } = useCountry();

  const projects = countryPrefix === '/mx' ? mxProjects : arProjects;

  return (
    <SectionLayout backgroundColor="white">
      <Box
        width="100%"
        paddingX="medium"
        maxWidth="1200px"
        paddingY={{ mobile: 'medium', desktop: 'large' }}
        marginY={{ mobile: 'medium', desktop: 'large' }}
        paddingTop={{ mobile: 'medium', desktop: 'xxxxxlarge' }}
        paddingBottom={{ mobile: 'medium', desktop: 'xsmall' }}
        margin="auto"
      >
        <Box marginY="large" marginX="xlarge">
          <Title fontSize="supportOurTeamTitle" fontWeight="bold">
            Algunos de los proyectos en donde están seguros tus ahorros:
          </Title>
        </Box>
        <SimpleCarousel
          dots={true}
          arrows={false}
          centerMode={false}
          width={{ mobile: '350px', desktop: '1162px' }}
          height={{ mobile: 'small', desktop: '405px' }}
          infinite={false}
          initialSlide={0}
          slidesToShow={isMobile ? 1 : 3}
        >
          {projects.map((item, i) => {
            return (
              <Box
                key={i}
                alignItems="center"
                marginLeft={{ mobile: 'xsmall', desktop: 'xsmall' }}
                marginRight={{ mobile: 'xsmall', desktop: 'xsmall' }}
                paddingLeft={{ mobile: 'xsmall', desktop: 'xlarge' }}
                paddingRight={{ mobile: 'xsmall', desktop: 'xlarge' }}
                marginTop={{ mobile: 'small', desktop: 'none' }}
                paddingTop={{ mobile: 'small', desktop: 'none' }}
                marginBottom={{ mobile: 'small', desktop: 'none' }}
                paddingBottom={{ mobile: 'small', desktop: 'none' }}
                tag="div"
                cursor="pointer"
                width={{ mobile: '350px', desktop: '854px' }}
                height={{ mobile: '450px', desktop: '570px' }}
                minHeight={{ mobile: '450px', desktop: '570px' }}
              >
                <VStack>
                  <Box>
                    <PropertiesCarousel project={item.images} />
                  </Box>
                  <Box
                    tag="header"
                    minHeight={{ mobile: '342px', desktop: '251px' }}
                  >
                    <VStack space="small">
                      <Title fontWeight="semibold" fontSize="_aboutBigText">
                        {item.title}
                      </Title>
                      <Copy fontWeight="regular" fontSize="bigText">
                        {item.description}
                      </Copy>
                    </VStack>
                  </Box>
                </VStack>
              </Box>
            );
          })}
        </SimpleCarousel>
      </Box>
    </SectionLayout>
  );
};
export default Projects;
