import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Ar from './locales/es-AR/translation.json';
import Mx from './locales/es-MX/translation.json';

const resources = {
  'es-AR': {
    translation: {
      ...Ar,
    },
  },
  'es-MX': {
    translation: {
      ...Mx,
    },
  },
};

i18n
  .use(initReactI18next)
  .use(LanguageDetector) // passes i18n down to react-i18next
  .init({
    resources,
    fallbackLng: 'es-AR', // Idioma a usar si no se detecta ninguno
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    debug: true,
  });

export default i18n;
