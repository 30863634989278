import { createGlobalStyle } from 'styled-components';
import { normalize } from 'styled-normalize';
import { BREAKPOINTS } from '@mollycule/lattice';

export const BASE_FONT_SIZE = 16;

export const fontSize = (px) => {
  const value = (px / BASE_FONT_SIZE) * 1;
  return `${value}rem`;
};

export const theme = {
  fontColorVariants: {
    black: '#364042',
    xDarkGreen: '#30C19D',
    darkGreen: '#21b993',
    green: '#3bd4ae',
    greenAmount: '#1bd70a',
    gray: '#737373',
    lightGray: '#bababa',
    purple: '#1F1646',
    white: '#fff',
    red: '#E25A48',
    darkRed: '#CA003D',
    darkBlue: '#18214d',
    xDarkBlue: '#1F1646',
    darkOrange: '#ff642a',
    yellow: '#ffb200',
    textLight: '#3e4462',
    pink: '#F97FB5',
    lightPink: 'rgba(249, 127, 181, 0.1)',
    meli: '#181623',
    blueLink: '#0029FF',
    textBlack: '#181623',
    xxDarkPurple: '#201646',
    fijo: '#CA003D',
    liquidez: '#4D7EA8',
    rentalFinal: '#A2E731',
    rentaAlquiler: '#F35613',
    mensualExterior: '#FFAD0C',

    // new design
    _green: '#008362',
    _darkGray: '#454545',
    _midGray: '#D6D6D6',
    _midLightGray: '#9D9D9C',
    _lightGray: '#E5E5E5',
    _purple: '#1F1646',
    _lightGreen: '#00AE83',
    _mainBlue: '#0073C7',
    disabled: '#e7e7e7',

    //renew
    primary: '#34EDC3',
    primaryHover: '#00C094',
    lightPrimary: '#B2FCEA',
    xlightPrimary: '#DFFFF8',
    secondary: '#D4FFF5',
    secondaryDark: '#33EDC4',
    link: '#3F3F8F',
    background: '#F4F4F4',
    default: '#000000',
    btnDisabled: '#9E9E9E',
    statusOk: '#16A24E',
    mercadoPago: '#019ee3',
    _lightPurple: '#845CFF',
    _darkPurple: '#3F1CAB',
    _lightGrayText: '#ABABAB',
    _switchLabel: '#A4A4A4',
    __purple900: '#211649',
    _neutral600: '#A9ACAC',
    informative600: '#236EDF',
    __lightGray2: '#BEBEBE',
    __darkPurple: '#7C54F9',
  },

  colors: {
    black: '#000000',
    darkBlack: 'rgba(0, 0, 0, 0.8)',
    lightBlack: 'rgba(0, 0, 0, 0.5)',
    xlightBlack: 'rgba(0, 0, 0, 0.1)',

    white: '#fff',
    midWhite: 'rgba(255, 255, 255, 0.7)',
    lightWhite: 'rgba(255, 255, 255, 0.5)',

    textBlack: '#181623',
    textLight: '#3e4462',

    xxDarkGreen: '#498441',
    xDarkGreen: '#418482',
    darkGreen: '#21b993',
    green: '#3bd4ae',
    _newGreen: '#69D0B0',
    greenAmount: '#1bd70a',
    lightBlue: '#B0F8E6',
    lightGreen: '#C4F2E7',
    xLightGreen: 'rgb(59, 212, 174, 0.2)',
    xAquaGreen: '#E4FEF8',
    xxDarkPurple: '#201646',
    xDarkPurple: '#1d1743',
    darkPurple: '#362e56',
    purple: '#1F1646',
    midPurple: 'rgba(56, 45, 98, 0.9)',
    lightPurple: '#E8E0FA',
    xlightPurple: 'rgba(75, 65, 132, 0.2)',

    gray: '#737373',
    lightGray: '#e3e3e3',
    _xxlightGray: '#ccc',
    xlightGray: '#f6f8f6',
    xxlightGray: '#F6F5F9',

    darkRed: '#CA003D',
    red: '#E25A48',
    lightRed: '#e74c3cbf',
    trust: '#ece7ff',

    pink: '#F97FB5',
    lightPink: 'rgba(249, 127, 181, 0.1)',

    darkBlue: '#18214d',
    xDarkBlue: '#1F1646',

    yellow: '#ffb200',
    darkYellow: '#c18a30',
    lightYellow: 'rgba(255, 178, 0, 0.1)',

    transparent: 'transparent',

    fijo: '#CA003D',
    liquidez: '#4D7EA8',
    rentalFinal: '#A2E731',
    rentaAlquiler: '#F35613',
    closed: '#757575',
    mensualExterior: '#FFAD0C',

    cian: '#30babd',
    granate: '#844151',
    mustard: '#bd7030',
    lila: '#84417e',

    facebookColor: '#4065b4',
    googleColor: '#4285f4',

    tertiaryLight: '#d8d8d8',
    textGray: '#bababa',
    separator: '#ededed',
    progressColor: '#e8e8e8',
    tabBorderInactive: '#f7f7f7',
    tabButtonInactive: '#212121',
    markerBg: '#dbdbdb',
    statusLine: '#dedede',
    meli: '#181623',

    darkViolet: '#181623',
    // new design
    _green: '#008362',
    _lightGreen: '#00AE83',
    _darkGreen: '#006c51',

    flex: '#4D7EA8',
    final: '#A2E731',
    mensual: '#FF9061',
    exterior: '#FFAD0C',

    lightOrange: '#ffecd7',

    _darkGray: '#454545',
    _midGray: '#D6D6D6',
    _midLightGray: '#9D9D9C',
    _lightGray: '#E5E5E5',
    _xlightGray: '#F3F3F3',
    _anotherGray: '#B8B8B8',

    _purple: '#1F1646',
    _darkBlue: '#09061C',
    disabled: '#e7e7e7',

    //renew
    primary: '#34EDC3',
    primaryHover: '#00C094',
    lightPrimary: '#B2FCEA',
    xlightPrimary: '#DFFFF8',
    secondary: '#D4FFF5',
    link: '#3F3F8F',
    background: '#F4F4F4',
    default: '#000000',
    academy: '#F7F5FF',
    resume: '#E4DFF6',
    _resume: '#F7F5FF',
    btnDisabled: '#9D9D9C',
    _lightRed: '#FCD8D3',
    _lightGrayBorderColor: '#858585',
    statusOk: '#16A24E',
    mercadoPago: '#019ee3',
    _lightPurple: '#845CFF',
    _darkPurple: '#3F1CAB',
    _gray: '#D9D9D9',
    _lightGrayText: '#ABABAB',
    _extraLigthPurple: '#F4EFFF',
    _green: '#29CF6B',
    __lightPurple2: '#AA93EF',
    __lightPurple3: '#A993EE',
    __lightGreen: '#F2FFFC',
    __purple: '#906DDF',
    __lightpurple: '#F6F2FF',
    informative600: '#236EDF',
    informative100: '#E0ECFF',
    _neutral600: '#A9ACAC',
    __lightPurple4: '#EEE8FF',
    __darkPurple: '#7C54F9',
    __lightGray3: '#DFE1E1',
    __lightPurple5: '#ECE6FF',
    __lightGray: '#F2F2F2',
  },
  fontSizes: {
    bigHeader: {
      mobile: fontSize(25),
      desktop: fontSize(50),
    },

    button: {
      mobile: fontSize(15),
      desktop: fontSize(18),
    },

    midHeader: {
      mobile: fontSize(20),
      desktop: fontSize(45),
    },

    bigTitle: {
      mobile: fontSize(34),
      desktop: fontSize(60),
    },
    title: {
      mobile: fontSize(28),
      desktop: fontSize(38),
    },
    midSizeTitle: {
      mobile: fontSize(22),
      desktop: fontSize(36),
    },
    midTitle: {
      mobile: fontSize(20),
      desktop: fontSize(35),
    },
    titleMeli: {
      mobile: fontSize(28),
      desktop: fontSize(41),
    },
    midTitleMeli: {
      mobile: fontSize(28),
      desktop: fontSize(35),
    },
    titleMeliBold: {
      mobile: fontSize(28),
      desktop: fontSize(39),
    },
    midSubTitleMeli: {
      mobile: fontSize(16),
      desktop: fontSize(24),
    },
    easyAndFastTitle: {
      mobile: fontSize(18),
      desktop: fontSize(22),
    },
    bannerTitle: {
      mobile: fontSize(30),
      desktop: fontSize(34),
    },
    bigHeading: {
      mobile: fontSize(18),
      desktop: fontSize(24),
    },
    heading: {
      mobile: fontSize(18),
      desktop: fontSize(21),
    },
    subtitle: {
      mobile: fontSize(16),
      desktop: fontSize(18),
    },
    bigCopy: {
      mobile: fontSize(14),
      desktop: fontSize(20),
    },
    goalButton: {
      mobile: fontSize(12),
      desktop: fontSize(16),
    },
    copyMobile: {
      mobile: fontSize(14),
      desktop: fontSize(16),
    },
    copy: {
      mobile: fontSize(15),
      desktop: fontSize(16),
    },
    smallCopy: {
      mobile: fontSize(12),
      desktop: fontSize(14),
    },
    label: {
      mobile: fontSize(12),
      desktop: fontSize(12),
    },
    small: {
      mobile: fontSize(11),
      desktop: fontSize(11),
    },
    constructionTitle: {
      mobile: fontSize(35),
      desktop: fontSize(45),
    },
    // new design
    giant: {
      mobile: fontSize(70),
      desktop: fontSize(120),
    },
    _bigHeader: {
      mobile: fontSize(26),
      desktop: fontSize(40),
    },
    text2033: {
      mobile: fontSize(20),
      desktop: fontSize(33),
    },
    text2024: {
      mobile: fontSize(20),
      desktop: fontSize(24),
    },
    _bigTitle: {
      mobile: fontSize(28),
      desktop: fontSize(30),
    },
    _newTitle: {
      mobile: fontSize(20),
      desktop: fontSize(30),
    },
    _newHomeTitle: {
      mobile: fontSize(22),
      desktop: fontSize(30),
    },
    _midHeader: {
      mobile: fontSize(23),
      desktop: fontSize(26),
    },
    _header: {
      mobile: fontSize(28),
      desktop: fontSize(35),
    },
    _bigText: {
      mobile: fontSize(18),
      desktop: fontSize(23),
    },
    _regularText: {
      mobile: fontSize(18),
      desktop: fontSize(23),
    },
    _stepText: {
      mobile: fontSize(20),
      desktop: fontSize(23),
    },
    _contentTitle: {
      mobile: fontSize(20),
      desktop: fontSize(22),
    },
    _text: {
      mobile: fontSize(15),
      desktop: fontSize(18),
    },
    _small: {
      mobile: fontSize(13),
      desktop: fontSize(15),
    },
    _xsmall: {
      mobile: fontSize(12),
      desktop: fontSize(13),
    },
    _modalTitle: {
      mobile: fontSize(20),
      desktop: fontSize(26),
    },
    _modalText: {
      mobile: fontSize(15),
      desktop: fontSize(18),
    },
    xxsmall: {
      mobile: fontSize(10),
      desktop: fontSize(11),
    },
    blogTitle: {
      mobile: fontSize(20),
      desktop: fontSize(20),
    },
    _goalsBigTitle: {
      mobile: fontSize(80),
      desktop: fontSize(150),
    },
    _goalsBig3Title: {
      mobile: fontSize(30),
      desktop: fontSize(60),
    },
    text2430: {
      mobile: fontSize(24),
      desktop: fontSize(30),
    },
    text2238: {
      mobile: fontSize(22),
      desktop: fontSize(38),
    },
    _goalsSimulatorTitle: {
      mobile: fontSize(28),
      desktop: fontSize(40),
    },
    _goalTextTooltip: {
      mobile: fontSize(11),
      desktop: fontSize(15),
    },
    _xxGoalText: {
      mobile: fontSize(10),
      desktop: fontSize(10),
    },
    text: {
      mobile: fontSize(12),
      desktop: fontSize(14),
    },
    bigText: {
      mobile: fontSize(14),
      desktop: fontSize(18),
    },
    bigTextResume: {
      mobile: fontSize(12),
      desktop: fontSize(18),
    },
    _aboutBigText: {
      mobile: fontSize(18),
      desktop: fontSize(30),
    },
    text14: {
      mobile: fontSize(14),
      desktop: fontSize(14),
    },
    text16: {
      mobile: fontSize(16),
      desktop: fontSize(16),
    },
    text18: {
      mobile: fontSize(18),
      desktop: fontSize(18),
    },
    text21: {
      mobile: fontSize(21),
      desktop: fontSize(21),
    },
    text1316: {
      mobile: fontSize(13),
      desktop: fontSize(16),
    },
    text1822: {
      mobile: fontSize(18),
      desktop: fontSize(22),
    },
    text1016: {
      mobile: fontSize(10),
      desktop: fontSize(16),
    },
    text1216: {
      mobile: fontSize(12),
      desktop: fontSize(16),
    },
    text1836: {
      mobile: fontSize(18),
      desktop: fontSize(36),
    },
    text1014: {
      mobile: fontSize(10),
      desktop: fontSize(14),
    },
    text1832: {
      mobile: fontSize(18),
      desktop: fontSize(32),
    },
    simulatorTitle: {
      mobile: fontSize(25),
      desktop: fontSize(45),
    },
    simulatorDescription: {
      mobile: fontSize(16),
      desktop: fontSize(25),
    },
    textHeading: {
      mobile: fontSize(16),
      desktop: fontSize(21),
    },
    footer: {
      mobile: fontSize(10),
      desktop: fontSize(12),
    },
    _bigCopy: {
      mobile: fontSize(17),
      desktop: fontSize(20),
    },
    _itemList: {
      mobile: fontSize(13),
      desktop: fontSize(25),
    },
    _landingResume: {
      mobile: fontSize(30),
      desktop: fontSize(55),
    },
    _landingResumeNumber: {
      mobile: fontSize(40),
      desktop: fontSize(80),
    },
    _landingResumeSymbols: {
      mobile: fontSize(30),
      desktop: fontSize(50),
    },
    /* Styled with 3 resolution */
    text202230: {
      mobile: fontSize(20),
      tablet: fontSize(22),
      desktop: fontSize(30),
    },
    text121214: {
      mobile: fontSize(12),
      tablet: fontSize(12),
      desktop: fontSize(14),
    },
    text161625: {
      mobile: fontSize(16),
      tablet: fontSize(16),
      desktop: fontSize(25),
    },
    text080810: {
      mobile: fontSize(8),
      tablet: fontSize(8),
      desktop: fontSize(10),
    },
    text080812: {
      mobile: fontSize(8),
      tablet: fontSize(8),
      desktop: fontSize(12),
    },
    text101012: {
      mobile: fontSize(10),
      tablet: fontSize(10),
      desktop: fontSize(12),
    },
    text141418: {
      mobile: fontSize(14),
      tablet: fontSize(14),
      desktop: fontSize(18),
    },
    text101014: {
      mobile: fontSize(10),
      tablet: fontSize(10),
      desktop: fontSize(14),
    },
    text141014: {
      mobile: fontSize(14),
      tablet: fontSize(10),
      desktop: fontSize(14),
    },
    text141416: {
      mobile: fontSize(14),
      tablet: fontSize(14),
      desktop: fontSize(16),
    },
    text141618: {
      mobile: fontSize(14),
      tablet: fontSize(16),
      desktop: fontSize(18),
    },
    text181836: {
      mobile: fontSize(18),
      tablet: fontSize(18),
      desktop: fontSize(36),
    },
    text121416: {
      mobile: fontSize(12),
      tablet: fontSize(14),
      desktop: fontSize(16),
    },
    text121414: {
      mobile: fontSize(12),
      tablet: fontSize(14),
      desktop: fontSize(14),
    },
    text181824: {
      mobile: fontSize(18),
      tablet: fontSize(18),
      desktop: fontSize(24),
    },
    text102235: {
      mobile: fontSize(20),
      tablet: fontSize(22),
      desktop: fontSize(35),
    },
    text121618: {
      mobile: fontSize(12),
      tablet: fontSize(16),
      desktop: fontSize(18),
    },
    text202222: {
      mobile: fontSize(20),
      tablet: fontSize(22),
      desktop: fontSize(22),
    },
    supportWhyTitle: {
      mobile: fontSize(24),
      desktop: fontSize(42),
    },
    supportInvestPill: {
      mobile: fontSize(12),
      desktop: fontSize(14),
    },
    supportPercentPill: {
      mobile: fontSize(16),
      desktop: fontSize(28),
    },
    supportPercentUnderPill: {
      mobile: fontSize(14),
      desktop: fontSize(18),
    },
    supportMediaCardTitle: {
      mobile: fontSize(17),
      desktop: fontSize(24),
    },
    supportOurTeamTitle: {
      mobile: fontSize(20),
      desktop: fontSize(36),
    },
    supportInvestTitle: {
      mobile: fontSize(32),
      desktop: fontSize(36),
    },
    supportAlliesTitle: {
      mobile: fontSize(20),
      desktop: fontSize(24),
    },
    supportAlliesSubTitle: {
      mobile: fontSize(14),
      desktop: fontSize(16),
    },
    companiesPreFooterSectionTitle: {
      mobile: fontSize(24),
      desktop: fontSize(36),
    },
    companiesBenefitsSectionKey: {
      mobile: fontSize(28),
      desktop: fontSize(50),
    },
    companiesSectionTitle: {
      mobile: fontSize(32),
      desktop: fontSize(44),
    },
    companiesBenefitsSectionText: {
      mobile: fontSize(16),
      desktop: fontSize(20),
    },
    companiesLogoSectionSubtitle: {
      mobile: fontSize(24),
      desktop: fontSize(32),
    },
    companiesLogoSectionText: {
      mobile: fontSize(16),
      desktop: fontSize(20),
    },
    companiesLogoSectionLogo: {
      mobile: fontSize(25),
      desktop: fontSize(25),
    },
  },
  medias: {
    mobile: 767,
    tablet: 1024,
    desktop: 1920,
  },
  radius: {
    xsmall: '3px',
    small: '6px',
    smallRight: '0px 6px 6px 0px',
    smallLeft: '6px 0px 0px 6px',
    input: '9px',
    inputRight: '0px 9px 9px 0px',
    inputLeft: '9px 0px 0px 9px',
    normal: '12px',
    medium: '15px',
    mid: '20px',
    large: '25px',
    xxlarge: '50px',
    xlarge: '50%',
    full: '100%',
    topBorder: '6px 6px 0 0',
    leftBorder: '6px 0 0px 6px',
    rightBorder: '0px 20px 20px 0px',
    bottomBorder: '0 0 14px 14px',
  },
  scale: {
    xsmall: '0.25rem',
    small: '0.5rem',
    medium: '1rem',
    midMedium: '0.6rem',
    standard: '1rem',
    large: '1.5rem',
    midLarge: '1.87rem',
    xlarge: '2rem',
    xxlarge: '2.5rem',
    xxxlarge: '3rem',
    xxxlarge5: '3.5rem',
    xxxxlarge: '4rem',
    xxxxmlarge: '4.5rem',
    xxxxxlarge: '5rem',
    xxxxxxlarge: '7rem',
    xxxxxxxlarge: '8rem',
    xxxxxxxxlarge: '9rem',

    auto: 'auto',
    none: '0',
    button: '1.87rem',
    negative: '-70px',
  },
  shadows: {
    medium: `0 2px 10px 0 rgba(75, 65, 132, 0.2)`,
    standard: '0 0 10px 0 rgba(189, 189, 189, 0.25)',
    large: '4px 4px 15px 1px rgba(31, 22, 70, 0.25)',
    new: '5px 8px 30px 2px rgba(0, 131, 98, 0.2)',
    selected: '5px 8px 30px 2px rgba(31, 22, 70, 0.6)',
    banner: '0px 4px 10px 0px rgba(98, 98, 98, 0.15)',
    card: '0px 0px 4px 0px rgba(98, 98, 98, 0.25)',
    none: 'none',
  },
  textShadow: {
    small: '3px 3px 8px #000000',
    white: '2px 2px 1px #353535',
  },
  weight: {
    superbold: '900',
    bold: '700',
    semibold: '600',
    medium: '500',
    regular: '400',
  },
  inputSizes: {
    xsmall: '100px',
    small: '150px',
    medium: '300px',
    full: '100%',
  },
  breakpoints: BREAKPOINTS,
};

export const getWeight = () => {
  return undefined;
};

export const GlobalStyle = createGlobalStyle`
  ${normalize}

  .carrousel-dots {
    // background: #404040;
    font-size: 2.5em;
    bottom: 5px;
    color: #  
    text-align: center;
    // size: 150px
  }

  .alliesCardCrop {
    width: 340px;
    height: 70px;
    overflow: hidden;
    backgroung-color: white
  }
        
  .alliesCardCrop img {
    background: #D4C0FF;
    background: linear-gradient(180deg, #D4C0FF, #F4F3F1);
    width: 138px;
    height: 138px;
    margin: 0px 0px -50px 0px;
    filter: blur(2px);
    opacity: 0.1,  
  }

  .alliesCardCropGradient {
    background: #F4F3F1;
    background: linear-gradient(180deg, #F4F3F1, #D4C0FF);
  }
    
  .slick-dots {
    
    font-size: 15px;
    bottom: 0px;
   
  }

  .slick-dots li::before {
    background: #c21a1a;
    font-size: 350px;
    text-color: #845CFF
    color: red
  }

  .slick-dots li button:before {
    font-size: 15px;
    text-color: red
    color: red
    // background: #845CFF;
    background-color: #845CFF;
  }

  .slick-dots li button:before {
    font-size: 15px;
    text-color: red
    color: red
    background-color: #845CFF;
  }
    
  .slick-dots li button:before {
    font-size: 13px;
    text-color: red
    color: red
    background-color: #845CFF;
  }

  .slick-active button {
    // background-color: #845CFF;
    color: #845CFF;
  }

  .slick-active button::before {
    // background-color: #845CFF;
    color: #F4F3F1;
  }

  .slick-dots slick-active button::before {
    color: #845CFF;
  }

  ul.slick-dots li.slick-active button::before {
    color: #845CFF;
  }

  body {
    font-family: 'Lato', sans-serif;
    font-weight: 500;
    font-size: ${BASE_FONT_SIZE}px;
    line-height: 1.5;
    &.setHidden, &.overflow-hidden {
      overflow: hidden;
    }
    background: white;
  }

  *, *::after, *::before {
    box-sizing: border-box;
  }

  button {
    background: none;
    margin: 0;
    border: none;
    padding: 0;
    cursor: pointer;

    &:focus {
      outline: none;
    }
  }

  h1, h2, h3, h4, h5, h6 {
    margin: 0;
    font-weight: 500;b
    line-height: 1.2;
  }

  p {
    margin: 0;
  }
  .tooltip {
    padding: 3px;
    border: 2px solid;
    border-radius: 4px;
    background-color: #eee;
    opacity: 0.6;
    justify-content: center;
    align-items: center;
  }
  .text-clamp-1 { 
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  .overflow {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  a {
    text-decoration: underline;
    color: inherit;
    font-weight: 600;
    font-size: inherit;
  }

  img {
    max-width: 100%;
    height: auto;
  }

  .img-cover {
    object-fit: cover;
  }

  .investment-details-image {
    height: 450px;
  }

  fieldset {
    margin: 0;
    padding: 0;
    border: none;
  }

  input, textarea, select {
    &::placeholder {
      color: ${theme.colors.gray};
      font-weight: light;
      font-style: italic;
    }

    &:focus {
      outline: none;
      border: 1px solid ${theme.colors.lightGreen};
    }

    &:disabled {
      background-color: hsl(0, 0%, 95%);
    }
  }

  textarea {
    min-height: 130px;
  }

  .visually-hidden {
    border: 0;
    clip: rect(0px, 0px, 0px, 0px);
    height: 1px;
    width: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    white-space: nowrap;
    position: absolute;
  }

  .addHover {
    &:hover {
      text-decoration: underline;
    }
  }

  .flickity-page-dot{
    &.is-selected {
      background: #25c18a;
    }
    &:focus{
      box-shadow: none;
    }
  }

  .projects-carousel-dashboard {
    .flickity-viewport {
      min-height: 480px;
    }
    .flickity-page-dot{
      &.is-selected {
        background: #25c18a;
      }
      &:focus{
        box-shadow: none;
      }
    }
    
    
    .flickity-page-dots {
      position: absolute;
      width: 100%;
      bottom: 100px;
      text-align: center;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
    }
  }

  .projects-carousel{
    .flickity-viewport {
      min-height: 470px;
    }
  }

  .google-carousel {
    .flickity-viewport {
      min-height: 350px;
    }
  }

  .flickity-viewport {
    .flickity-slider div article {
      transform: scale(0.7);
      filter: grayscale(1);
      transition: 0.5s;
    }

    .flickity-slider div.is-selected article {
      transform: scale(1);
      filter: grayscale(0);
      transition: 0.5s;
    }
  }

  .flickity-page-dots .dot{
    background: ${theme.colors.green};
    width: 15px;
    height: 15px;
  }
  

  .slick-slider {
    * {
      &:focus {
        outline: none;
      }
    }

    &.home {
      .slick-slide {
        padding-top: 0;
        padding-bottom: 0;
      }
    }
    
    .slick-slide {
      padding-top: 15px;
      padding-bottom: 15px;
      width: 390px
    }

    .buttonAction {
          position: absolute;
          @media (min-width: 769px) {
            bottom: -40px;
            min-width: 210px;
            z-index: 10;
            &.prev {
              left: 57px;
            }
            &.next {
              right: 57px;
            }
          }
          @media (max-width: 768px) {
            top: 0;
            z-index: 1;
            &.prev {
              left: 0;
            }
            &.next {
              right: 0;
            }
          }
        }
  }
  
  .slick-prev {
    left: 15px;
    position: absolute;
    z-index: 1;
  }

  .slick-next {
    right: 15px;
    position: absolute;
  }

  .slick-prev:before, .slick-next:before {
    color: ${theme.colors.white} ;
    text-color: ${theme.colors.white};
    font-size: 30px;
  }

  .footer-nav-wrapper {
    a {
      text-decoration: none;
      font-weight: 400;
    }
  }

  .footer-socials {
    a {
      margin-right: 15px;
    }
  }

  .useSpanColor {
    span {
      color: ${theme.fontColorVariants.green}
    }
  }

  .Toastify {

    &__toast {
      &--warning {
        background: ${theme.colors.yellow} !important;
      }

      &--danger {
        background: ${theme.colors.red} !important;
      }

      &--info {
        background: ${theme.colors.purple} !important;
      }

      &--success {
        background: ${theme.colors.green} !important;
      }
    }
  }
  .content-article {
    font-size: 20px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0;
    text-align: left;
  }

  .Toastify__toast-container--bottom-right {
    bottom: 4em;
  }

  .sticky {
    position: fixed;
    z-index: 2;
    width: 94%!important;
    max-width: 1370px;
    top: 65px;
  }

  .link {
    &__outline {
      
      a {
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        padding-left: 1.5rem;
        padding-right: 1.5rem;
        width: 100%;
        display: block;
        border-radius: ${theme.radius.large};
      }

      &--white {
        a {
          border: 1px solid ${theme.colors.white};
          color: ${theme.colors.white};
        }
      }
    }
  }

  .react-select-method > div {
    border-color: #3BD4AE!important;
  }

  .react-datepicker-wrapper {
    width: 100%;
  }

  .react-datepicker {
    &__month-select, &__year-select {
      border: none;
      padding: 2.5px 5px;
    }
  }

  .text-green {
    color: ${theme.colors.green}
  }

  .rh5v-DefaultPlayer_component {
    background-color: transparent!important;
  }
  
  .rh5v-Overlay_inner {
    background-color: transparent!important;
  }
  
  .rh5v-Overlay_component {
    cursor: auto!important;
  }

  .rh5v-DefaultPlayer_component {
    position: absolute!important;
    top: -40px;
    left: 25%;
    max-width: 230px;
    border-radius: 4px;
    @media (max-width: ${theme.medias.mobile}px) {
      top: 30px;
      width: 100%;
      max-width: 240px;
      left: 0;
      right: 0;
      margin: auto;
    }
  }
  .rh5v-DefaultPlayer_controls {
    display: none!important;
  }

  .country-codes {
    .country-input:focus {
      border-color: rgba(59,212,174,0.5)!important;
      box-shadow: none!important;
    }
  }

  .react-tel-input .selected-flag.open:before, .react-tel-input .selected-flag:before {
    display: none!important;
  }

  .custom-scroll-bar {
    ::-webkit-scrollbar {
      width: 8px;
    }
    
    ::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px #E5E5E5;
      background: #F4F4F4;
      border-radius: 2px;
    }
     
    ::-webkit-scrollbar-thumb {
      background: #D9D9D9; 
      border-radius: 3px;
    }
    
    ::-webkit-scrollbar-thumb:hover {
      background: #8d83b9; 
    }
  }
  #chatIframe {
    right: 20px!important;
    height: 20px!important;
    width: 20px!important;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type=number] {
    -moz-appearance: textfield;
  }

  @media (max-width: ${theme.medias.mobile}px) {
    /*.slick-arrow {
      display: none !important;
    }*/

    .order-sm-0 {
      order: 0;
    }

    .order-sm-1 {
      order: 1;
    }
    #chatIframe {
      right: 10px!important;
    }
  }

  @media (max-width: ${theme.medias.tablet}px) {
    /*.slick-arrow {
      display: none !important;
    }*/

    .order-md-0 {
      order: 0;
    }

    .order-md-1 {
      order: 1;
    }
  }

  @media (max-width: 756px) {
    .footer-logos {
      display: flex!important;
      flex-direction: column!important;
      align-items: start!important;
    }
  }
  .app {
    opacity: 0;
  }
  .is-loaded {
    transition: all .5s!important;
    -webkit-transition: all .5s!important;
    opacity: 1!important;
  }
  .iUXSSb {
    bottom: 80px!important;
  }
   @media (max-width: 756px) {
    #video-home {
      width: 70%;
      height: 100%;
    }
    .rh5v-DefaultPlayer_component {
      display: flex;
      justify-content: center;
    }
    .flicking-panel {
      align-items: flex-end;
      border-radius: 5px;
      box-sizing: border-box;
      display: flex;
      justify-content: center;
      margin-bottom: 10px;
      margin-right: 10px;
      padding: 30px 0!important;
      position: relative;
      width: 100%!important;
    }
    .flicking-camera {
      display: flex;
      flex-direction: row;
      height: 100%;
      position: relative;
      width: 100%!important;
      will-change: transform;
      z-index: 1;
    }
  }
  .flicking-viewport {
    margin-left: auto;
    margin-right: auto;
    transition: height .5s;
    width: 100%;
   
    position: relative;
    overflow: hidden;
  }
  .flicking-camera {
    display: flex;
    flex-direction: row;
    height: 100%;
    position: relative;
    width: 100%;
    will-change: transform;
    z-index: 1;
  }
  .flicking-panel {
    align-items: flex-end;
    border-radius: 5px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
    margin-right: 10px;
    padding: 30px 20px;
    position: relative;
    width: 100%;
  }
  .indicator {
    display: flex;
    margin-top: 10px;
  }
  .monto-moneda-container {
    position: relative;
    display: flex;
    width: 100%;
  }

  .monto-moneda-container > input {
    width: 88%;
    height: 45px;
    border: 0!important;
    box-sizing: border-box; /* Agregamos esto para que el ancho sea incluyendo el padding */
    padding: 10px 60px 10px 10px; /* Añadimos padding para que el texto no se solape con el select */
    border-radius: 4px;
    outline: none;
    font-size: 16px;
  }

  .monto-moneda-container > select {
    margin-right: 10px;
    width: 10%;
    height: 45px;
    border: 0;
  }

  #moneda {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    border: none;
    background: transparent;
    outline: none;
    font-size: 16px;
    padding: 10px 15px;
    cursor: pointer;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }

  #moneda:hover,
  #moneda:focus {
    background-color: rgba(0, 0, 0, 0.05);
  }
  @media (max-width: 756px) {
    .monto-moneda-container > input {
      width: 77%;
      height: 45px;
      border: 0;
    }
    .monto-moneda-container > select {
      margin-right: 10px;
      width: 18%;
      height: 45px;
      border: 0;
    }
  }
  .tooltip-container {
    position: relative;
    display: inline-block;
    cursor: pointer;
  }

  .tooltip-content {
    position: absolute;
    box-shadow: 4px 4px 15px 1px rgba(31, 22, 70, 0.25);
    width: 450px;
    height: 93px;
    bottom: 125%;
    left: 50%;
    transform: translateX(-50%);
    background-color: #fff;
    font-size: 15px;
    padding: 5px 10px;
    border-radius: 4px;
    z-index: 10;
    display: flex;
    align-items: center;
  
    /* Flecha del tooltip */
    &:after {
      content: '';
      position: absolute;
      top: 100%;
      left: 50%;
      margin-left: -5px;
      border-width: 5px;
      border-style: solid;
      border-color: #555 transparent transparent transparent;
    }
  }

  .tooltip-content-2 {
    position: absolute;
    box-shadow: 4px 4px 15px 1px rgba(31, 22, 70, 0.25);
    width: 277px;
    height: 55px;
    bottom: 125%;
    left: 50%;
    transform: translateX(-50%);
    background-color: #fff;
    font-size: 15px;
    padding: 5px 10px;
    border-radius: 4px;
    z-index: 10;
    display: flex;
    align-items: center;

    /* Flecha del tooltip */
    &:after {
      content: '';
      position: absolute;
      top: 100%;
      left: 50%;
      margin-left: -5px;
      border-width: 5px;
      border-style: solid;
      border-color: #555 transparent transparent transparent;
    }
  }
  
  .tooltip-content-mobile {
    position: absolute;
    box-shadow: 4px 4px 15px 1px rgba(31, 22, 70, 0.25);
    width: 200px;
    bottom: 125%;
    left: 50%;
    transform: translateX(-50%);
    background-color: #fff;
    font-size: 15px;
    padding: 5px 10px;
    border-radius: 4px;
    z-index: 10;
    display: flex;
    align-items: center;
  
    /* Flecha del tooltip */
    &:after {
      content: '';
      position: absolute;
      top: 100%;
      left: 50%;
      margin-left: -5px;
      border-width: 5px;
      border-style: solid;
      border-color: #555 transparent transparent transparent;
    }
  }

.tooltip-content-block {
  position: absolute;
  box-shadow: 4px 4px 15px 1px rgba(31, 22, 70, 0.25);
  width: 450px;
  height: auto;
  bottom: 125%;
  left: 50%;
  transform: translateX(-50%);
  background-color: #fff;
  font-size: 15px;
  padding: 10px 10px;
  border-radius: 4px;
  z-index: 10;
  display: block;
  align-items: center;

  /* Flecha del tooltip */
  &:after {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
  }
}
  .tooltip-content-block-mobile {
  position: absolute;
  box-shadow: 4px 4px 15px 1px rgba(31, 22, 70, 0.25);
  left: -104px;
    width: 300px;
    bottom: 125%;
  transform: translateX(-50%);
  background-color: #fff;
  font-size: 15px;
  padding: 5px 10px;
  border-radius: 4px;
  z-index: 10;
  display: table;
  align-items: center;

  /* Flecha del tooltip */
  &:after {
    content: '';
    position: absolute;
    top: 100%;
    left: 87%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
  }
}
  @keyframes pulse {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.1); /* Puedes ajustar este valor para aumentar o disminuir la intensidad del pulso */
    }
    100% {
      transform: scale(1);
    }
  }

  .pulse-effect {
    display: inline-block;
    animation: pulse 1s infinite alternate;
    transition: transform .3s ease-in-out;
  }

  @keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }

  .text-transition {
    animation: fadeIn 0.5s ease-in-out; /* Puedes ajustar la duración si es necesario */
  }

  @keyframes slideIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }

  @keyframes slideOut {
    0% { opacity: 1; }
    100% { opacity: 1; }
  }

  .slide-in {
    animation: slideIn 0.3s ease-in-out;
  }

  .slide-out {
    animation: slideOut 0.3s ease-in-out;
  }

  .meta-input {
    width: 200px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-weight: bold;
  }

  .meta-input::placeholder {
    font-weight: normal;
  }
`;
